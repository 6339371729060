import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import Layout from "../components/Layout";
import Button from "../components/Button";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

export default () => {
  const { thanksImage } = useStaticQuery(graphql`
    query {
      thanksImage: file(relativePath: { eq: "404.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);
  return (
    <Layout whiteHeader>
      <section>
        <div className="container">
          <div className="image-container">
            <PreviewCompatibleImage imageInfo={thanksImage} alt="404 image" />
            <h1>Page is Not Found</h1>
            <p>Sorry the page you are looking for is not available.</p>
            <div className="btn-container">
              <Button href="/" isInternal>
                Back to Home
              </Button>
            </div>
          </div>
        </div>
        <style jsx>{`
          section {
            padding: 32px;
          }

          .container {
            max-width: 1278px;
            margin: 0 auto;
          }

          .image-container {
            width: 100%;
            max-width: 500px;
            margin: 0 auto;
            text-align: center;
            font-family: "Open Sans", sans-serif;
          }

          h1 {
            margin: 0;
          }

          p {
            margin: 16px auto;
            max-width: 400px;
            margin-bottom: 32px;
          }

          .btn-container {
            display: flex;
            justify-content: center;
          }
        `}</style>
      </section>
    </Layout>
  );
};
